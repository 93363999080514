/* Copyright Levelise Ltd 2024 */
import React, { useContext, useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import './index.css';
import NavBar from '../../components/NavBar';
import { download, PERMISSIONS, routes } from '../../utils/constants';
import FleetCustomerList from '../../components/FleetCustomerList';
import TabContainer from '../../components/TabContainer';
import { useAlert } from 'react-alert';
import { formatTimestamp, hasPermission } from '../../utils/utils';
import FleetService from '../../services/fleet-service';
import { colors } from '../../utils/chart';
import Popup from '../../components/Popup';
import { Button } from '../../components/Form';
import UserService from '../../services/user-service';
import PopupWithInput from '../../components/PopupWithInput';
import config from '../../config';
import FleetContext from '../../contexts/FleetContext';
import DownloadBtn from '../../components/DownloadBtn';

const allowedUploadFileTypesForCredit = [
	'text/csv',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const ButtonWithFileSelector = ({
	buttonTitle,
	buttonBackground,
	buttonColor,
	onFileChange,
	allowedFileTypes,
	multipleSelection,
	loading,
	fileExtensionsInfo,
	additionalAction,
	datacy,
}) => {
	return (
		<span className="upload-credits-button-container">
			<label className="upload-credits-button">
				<span
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						width: 150,
						height: 40,

						backgroundColor: buttonBackground,
						color: buttonColor,
						fontSize: 12,
						borderRadius: 3,
						fontFamily: 'Inter-Light',
						paddingTop: 1.5,
						paddingBottom: 1.5,
						paddingLeft: 15,
						paddingRight: 15,
					}}
					onClick={() => {
						if (additionalAction) {
							additionalAction(buttonTitle);
						}
					}}
					data-cy={datacy || ''}
				>
					{buttonTitle}
				</span>
				<input
					style={{ display: 'none' }}
					type="file"
					onChange={onFileChange}
					accept={allowedFileTypes}
					multiple={multipleSelection || false}
					disabled={loading}
				/>
			</label>
			{fileExtensionsInfo ? <span className="extension-text">({fileExtensionsInfo})</span> : null}
		</span>
	);
};

const CustomersCreditFilesTable = ({ customerCreditsFiles, downloadState, handleDownloadFile }) => (
	<div className="scrollable-table-credits customers-credits-table">
		<table border="0" cellPadding="2" cellSpacing="0" width="100%" data-cy="credits_table">
			<thead>
				<tr>
					<th className="table-col-2 col-name left-data">Run time</th>
					<th className="table-col-3 col-name left-data first-column-padding" style={{ minWidth: '65px' }}>
						Bankline file
					</th>
					<th className="table-col-3 col-name left-data first-column-padding" style={{ minWidth: '65px' }}>
						Reconciliation file
					</th>
				</tr>
			</thead>
			<tbody>
				{customerCreditsFiles && customerCreditsFiles.length ? (
					<>
						{customerCreditsFiles?.map((file, i) => {
							const date = file?.runTimestampSec
								? formatTimestamp(file?.runTimestampSec, 'Europe/London', 'dd/MM/yy')
								: '-';
							const time = file?.runTimestampSec
								? formatTimestamp(file?.runTimestampSec, 'Europe/London', 'HH:mm:ss')
								: '-';

							return (
								<tr key={i} width="100%">
									<td className="table-col-2 responsiveText left-data">
										{date || '-'}
										<br />
										{time || '-'}
									</td>
									<td className="table-col-3 responsiveText left-data first-column-padding">
										<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: 5 }}>
											<span
												style={{
													fontSize: '0.75rem',
												}}
											>
												{file?.bankline?.name}
											</span>
											{file?.bankline?.id ? (
											<DownloadBtn
												downloadState={downloadState}
												clickDownload={() => handleDownloadFile(file?.bankline?.id, file?.bankline?.name)}
												style={{
													position: 'unset',
													marginLeft: 5,
													minWidth: 20
												}}
											/>
										) : null}
										</div>
									</td>
									<td className="table-col-3 responsiveText left-data first-column-padding">
										<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',  paddingRight: 5 }}>
											<span
												style={{
													fontSize: '0.75rem',
												}}
											>
												{file?.reconciliation?.name}
											</span>
											{file?.reconciliation?.id ? (
											<DownloadBtn
												downloadState={downloadState}
												clickDownload={() => handleDownloadFile(file?.reconciliation?.id, file?.reconciliation?.name)}
												style={{
													position: 'unset',
													marginLeft: 5,
													minWidth: 20
												}}
											/>
										) : null}
										</div>
									</td>
								</tr>
							);
						})}
					</>
				) : null}
			</tbody>
		</table>
	</div>
);

function FleetCustomersRoute() {
	const Alert = useAlert();

	const fleetContext = useContext(FleetContext);

	const [loading, setLoading] = useState(false);
	const [downloading, setDownloading] = useState(false);
	const [generatingCredits, setGeneratingCredits] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);

	const [passwordModalVisible, setPasswordModalVisible] = useState(false);
	const [password, setPassword] = useState('');
	const [passwordValidationError, setPasswordValidationError] = useState('');

	const [file, setFile] = useState(null);
	const [confirmationMessage, setConfirmationMessage] = useState(null);

	const [uploadAction, setUploadAction] = useState('');
	const [downloadAction, setDownloadAction] = useState('');

	const [showCredits, setShowCredits] = useState(false);

	const [downloadState, setDownloadState] = useState(download);
	const [customerCreditsFilesList, setCustomerCreditsFilesList] = useState([]);

	const handleUploadBill = async () => {
		if (file && hasPermission(PERMISSIONS.CAN_EDIT_CUSTOMER_CONSENT)) {
			setLoading(true);
			try {
				if (file && file?.name) {
					let formData = new FormData();
					formData.append('file', file, `${file.name}`);

					const res = await FleetService.createCredits(formData);

					if (res && res.success) {
						setModalVisible(false);
						setFile(null);
						setUploadAction('');
						Alert.success('Credits created successfully.');
					}
				}
				setLoading(false);
			} catch (error) {
				setLoading(false);
				console.log(error);
				Alert.error('Something went wrong, please try again!');
			}
		}
	};

	const handleUploadProcessedCredits = async () => {
		if (file && hasPermission(PERMISSIONS.CAN_EDIT_CUSTOMER_CONSENT)) {
			setLoading(true);
			try {
				if (file && file?.name) {
					let formData = new FormData();
					formData.append('file', file, `${file.name}`);

					const res = await FleetService.uploadProcessedCredits(formData);

					if (res && res.success) {
						setModalVisible(false);
						setFile(null);
						setUploadAction('');
						Alert.success('Processed credits uploaded successfully.');
					}
				}
				setLoading(false);
			} catch (error) {
				setLoading(false);
				console.log(error);
				Alert.error('Something went wrong, please try again!');
			}
		}
	};

	const onModalConfirm = () => {
		if (uploadAction === 'Upload credits') {
			handleUploadBill();
		}

		if (uploadAction === 'Upload processed credits') {
			handleUploadProcessedCredits();
		}
	};

	const onFileChange = async (e) => {
		try {
			// check file length
			if (e.target.files?.length !== 1) {
				Alert.show('Upload failed');
				e.target.value = '';
				setLoading(false);
				return;
			}

			const selectedFile = e.target.files[0];

			// check file type
			if (!allowedUploadFileTypesForCredit.includes(selectedFile.type)) {
				Alert.show('Invalid file type');
				e.target.value = '';
				setLoading(false);
				return;
			}
			e.target.value = '';
			setConfirmationMessage(`Are you sure you want to upload this file? \n\n ${selectedFile.name}`);
			setFile(selectedFile);
			setModalVisible(true);
			e.target.value = '';
		} catch (error) {
			Alert.error('Something went wrong, please try again!');
			e.target.value = '';
		}
	};

	const handleCloseModalClick = () => {
		setModalVisible(false);
		setFile(null);
		setUploadAction('');
	};

	const onCustomersListClick = () => {
		setPasswordModalVisible(true);
		setDownloadAction('customers-list');
	};

	const onCreditsOutcomeClick = () => {
		setPasswordModalVisible(true);
		setDownloadAction('credit-outcomes');
	};

	const onExcludedPaymentsClick = () => {
		setPasswordModalVisible(true);
		setDownloadAction('excluded-payments');
	};

	const handleGenerateCredits = async () => {
		if (hasPermission(PERMISSIONS.CAN_EDIT_CUSTOMER_CONSENT)) {
			setGeneratingCredits(true);
			try {
				const res = await FleetService.generatePaymentFiles();

				if (res && res.success) {
					Alert.success('Payment files created successfully.');
				}
				setGeneratingCredits(false);
			} catch (error) {
				setGeneratingCredits(false);
				console.log(error);
				Alert.error('Something went wrong, please try again!');
			}
		}
	};

	const handleDownloadCustomersCSVZip = async () => {
		try {
			setDownloading(true);
			const response = await FleetService.getUserCustomersCSV(UserService.getUsername(), password);
			if (!response.ok) throw new Error(response);

			const filename = 'customers-list.zip';
			const blob = await response.blob();

			saveAs(blob, filename);

			setPassword('');
			setPasswordValidationError('');
			setPasswordModalVisible(false);
			setDownloading(false);
		} catch (error) {
			console.log(error);
			setDownloading(false);
		}
	};

	const handleDownloadCreditOutcomes = async () => {
		try {
			setDownloading(true);
			const response = await FleetService.getPaymentOutcome(password);
			if (!response.ok) throw new Error(response);

			const filename = 'Credit-outcomes.zip';
			const blob = await response.blob();

			saveAs(blob, filename);

			setPassword('');
			setPasswordValidationError('');
			setPasswordModalVisible(false);
			setDownloading(false);
		} catch (error) {
			console.log(error);
			setDownloading(false);
		}
	};

	const handleDownloadExcludedPayments = async () => {
		try {
			setDownloading(true);
			const response = await FleetService.getExcludedPayments(password);
			if (!response.ok) throw new Error(response);

			const filename = 'Excluded-payments.zip';
			const blob = await response.blob();

			saveAs(blob, filename);

			setPassword('');
			setPasswordValidationError('');
			setPasswordModalVisible(false);
			setDownloading(false);
		} catch (error) {
			console.log(error);
			setDownloading(false);
		}
	};

	const onDownloadClick = () => {
		if (downloadAction === 'customers-list') {
			handleDownloadCustomersCSVZip();
		}

		if (downloadAction === 'credit-outcomes') {
			handleDownloadCreditOutcomes();
		}

		if (downloadAction === 'excluded-payments') {
			handleDownloadExcludedPayments();
		}
	};

	const validPasswordPattern = /^[a-zA-Z0-9.!#$%@&*+`()"-/=?^~]*$/;

	const handlePasswordChange = (value) => {
		if (validPasswordPattern.test(value)) {
			setPassword(value);
			setPasswordValidationError('');
		} else {
			setPasswordValidationError('Cannot enter some special characters.');
		}
	};

	const fetchCurrentDrus = async () => {
		try {
			const res = await FleetService.getDruList();
			if (res) {
				fleetContext.setCurrentDrusForCustomerSearch(res);
			}
		} catch (err) {
			fleetContext.setCurrentDrusForCustomerSearch([]);
		}
	};

	const handleDownloadFill = async (fileId, fileName) => {
		if (
			hasPermission(PERMISSIONS.CAN_EDIT_CUSTOMER_CONSENT) &&
			fileId
		) {
			setDownloadState('loading');
			let url;
			try {
				const res = await FleetService.getCreditRunFile(fileId);

				if (res && res?.success && Object.hasOwn(res, 'uri')) {
					const response = await fetch(res.uri);

					if (!response.ok) {
						throw new Error('Network response was not OK');
					}

					const reader = response.body.getReader();
					const chunks = [];
					let done = false;

					while (!done) {
						const { value, done: readerDone } = await reader.read();
						done = readerDone;
						if (value) {
							chunks.push(value);
						}
					}

					const blob = new Blob(chunks, { type: response.headers.get('Content-Type') });

					const filename = fileName;
			
					saveAs(blob, filename);
				}

				if (url) {
					URL.revokeObjectURL(url);
				}

				setDownloadState(download);
			} catch (err) {
				setDownloadState(download);
			}
		}
	};

	const fetchCustomerCreditsFileList = async () => {
		try {
			const res = await FleetService.getCreditRuns();
			if (res && res?.runs) {
				setCustomerCreditsFilesList(res.runs);
			}
		} catch (err) {
			console.log('While fetching credits list: ', err);
		}
	};

	useEffect(() => {
		if (!fleetContext.tokenUpdating) {
			fetchCustomerCreditsFileList();
		}
	}, [fleetContext.tokenUpdating]);

	useEffect(() => {
		if (!fleetContext.tokenUpdating) {
			fetchCurrentDrus();
		}
	}, [fleetContext.tokenUpdating]);

	useEffect(() => {
		if (UserService.hasPreferences()) {
			const preference = UserService.getPreferences();
			if (preference[config.showCreditsManagement] === 'show') {
				setShowCredits(true);
			} else {
				setShowCredits(false);
			}
		}
	}, []);

	return (
		<div>
			<NavBar route={routes.fleet_customer} />
			<div className="fleet-customer-container">
				<div className={`left-container ${!showCredits ? 'credits-hidden' : ''}`}>
					{showCredits ? (
						<TabContainer
							title="Credits"
							containerStyle={{
								borderWidth: 1,
								borderColor: 'rgba(187, 187, 187, 0.33)',
								borderStyle: 'solid',
								borderRadius: 3,
							}}
							data-cy="fleet-customers-credits-section"
						>
							<div className="credits-container">
								<div className="fleet-customer-buttons-container">
									<ButtonWithFileSelector
										buttonTitle="Upload credits"
										buttonBackground={colors.orangeNew}
										buttonColor={colors.white}
										allowedFileTypes={allowedUploadFileTypesForCredit.join(',')}
										fileExtensionsInfo=".xlsx, .csv"
										loading={loading}
										onFileChange={onFileChange}
										additionalAction={(value) => setUploadAction(value)}
										datacy="upload-credits-button"
									/>
									<Button
										className="buttons-customer done-button-customer fleet-customer-action-button"
										style={{
											backgroundColor: '#e86035',
											marginLeft: 0,
										}}
										onClick={handleGenerateCredits}
										disabled={generatingCredits}
										data-cy="generate-credits-button"
									>
										{!generatingCredits ? 'Generate credits' : 'Generating...'}
									</Button>
									<ButtonWithFileSelector
										buttonTitle="Upload processed credits"
										buttonBackground={colors.orangeNew}
										buttonColor={colors.white}
										allowedFileTypes={allowedUploadFileTypesForCredit.join(',')}
										fileExtensionsInfo=".xlsx, .csv"
										loading={loading}
										onFileChange={onFileChange}
										additionalAction={(value) => setUploadAction(value)}
										datacy="upload-processed-credits"
									/>
								</div>
								<div className="table-container">
									<CustomersCreditFilesTable
										downloadState={downloadState}
										customerCreditsFiles={customerCreditsFilesList}
										handleDownloadFile={handleDownloadFill}
									/>
								</div>
							</div>
						</TabContainer>
					) : null}
					<TabContainer
						title="Reports"
						containerStyle={{
							borderWidth: 1,
							borderColor: 'rgba(187, 187, 187, 0.33)',
							borderStyle: 'solid',
							borderRadius: 3,
						}}
						data-cy="fleet-customers-reports-section"
					>
						<div className="fleet-customer-buttons-container">
							<Button
								className="buttons-customer done-button-customer fleet-customer-action-button"
								style={{
									backgroundColor: 'rgb(171, 46, 46)',
								}}
								onClick={onCustomersListClick}
								disabled={downloading}
								datacy="customers-list-button"
							>
								Customers list
							</Button>
							<Button
								className="buttons-customer done-button-customer fleet-customer-action-button"
								style={{
									backgroundColor: 'rgb(171, 46, 46)',
								}}
								onClick={onCreditsOutcomeClick}
								disabled={downloading}
								datacy="credit-outcomes-button"
							>
								Credit outcomes
							</Button>
							<Button
								className="buttons-customer done-button-customer fleet-customer-action-button"
								style={{
									backgroundColor: 'rgb(171, 46, 46)',
								}}
								onClick={onExcludedPaymentsClick}
								disabled={downloading}
								datacy="exclude-payments-button"
							>
								Excluded payments
							</Button>
						</div>
					</TabContainer>
				</div>
				<div
					className="fleet-customers-search-container container-border-style"
					data-cy="fleet-customers-customers-search-section"
				>
					<FleetCustomerList />
				</div>
			</div>
			{hasPermission(PERMISSIONS.CAN_EDIT_CUSTOMER_CONSENT) && passwordModalVisible && (
				<PopupWithInput
					message="Please provide a password for the downloaded file."
					type="password"
					placeholder="Password"
					value={password}
					setValue={handlePasswordChange}
					showError={passwordValidationError}
					errorMessage={passwordValidationError}
					cancelButtonLabel="Cancel"
					onCancelClick={() => {
						setPassword('');
						setPasswordValidationError('');
						setPasswordModalVisible(false);
					}}
					onPrimaryButtonClicked={onDownloadClick}
					primaryButtonDisabled={password.length === 0 || downloading}
					primaryButtonLabel="Download"
				/>
			)}
			{hasPermission(PERMISSIONS.CAN_EDIT_CUSTOMER_CONSENT) && modalVisible && (
				<Popup
					type="command"
					text={confirmationMessage}
					onConfirm={onModalConfirm}
					confirm={'Upload'}
					onClose={handleCloseModalClick}
					close={'Cancel'}
					textStyle={{
						marginTop: 0,
						padding: 10,
					}}
					disableConfirm={!file || loading}
				/>
			)}
		</div>
	);
}

export default FleetCustomersRoute;
