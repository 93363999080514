/* Copyright Levelise Ltd 2019-2024 */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { format, getUnixTime, subDays } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { Chart } from 'chart.js';
import { getSinceAndBefore, getResolutionAndAddDay } from '../../utils/utils';
import config from '../../config';
import FacilityContext from '../../contexts/FacilityContext';
import FleetService from '../../services/fleet-service';
import UserService from '../../services/user-service';

import EnergyChart from './charts/energy';
import DemandResponseChart from './charts/demandResponse';
import DruStateChart from './charts/druState';
import BatteryChart from './charts/battery';
import HotWaterChart from './charts/hotWater';
import HouseChart from './charts/house';
import ConnectivityChart from './charts/connectivity';

import {
	TIME_FRAMES,
	PERMISSIONS,
	distinct,
	CONTRACT_TIMEFRAME,
	timestampSec,
	timestampMsec,
	timestampDay,
	resolutions,
} from '../../utils/constants';
import { hasPermission, filterRecordsByTimeframe } from '../../utils/utils';
import CollapsiblePanel from './collapsiblePanel';
import './index.css';
import FleetContext from '../../contexts/FleetContext';

const energyFlow = 'Energy Flow';
const demandResponse = 'Demand Response';
const druState = 'DRU State';
const connectivity = 'Connectivity';
const battery = 'Battery';
const hotWater = 'Hot Water';
const house = 'House';

const LineChartsFacility = ({ facilityName }) => {
	const facilityContext = useContext(FacilityContext);
	const fleetContext = useContext(FleetContext);
	const outsideRef = useRef();
	const intervalId = useRef();
	const hasFmRef = useRef();
	const timezone = facilityContext.facility.timezone;
	const hasHotWater = !!facilityContext.facility.hasOwnProperty('hotWaterTank');
	const hasBattery = !!facilityContext.facility.hasOwnProperty('batterySystem');
	const isTypeDC = hasBattery && (facilityContext.facility.batterySystem?.meteringType?.id & 0x40) > 0 ;
	const canAccessDrBattery = hasPermission(PERMISSIONS.CAN_ACCESS_DR_BATTERY);
	const [innerWidth, setInnerWidth] = useState(window.innerWidth);

	const [collapseEF, setCollapseEF] = useState(true); //Energy Flow
	const [collapseDR, setCollapseDR] = useState(true); //Demand Response
	const [collapseDS, setCollapseDS] = useState(true); // Dru State
	const [collapseCO, setCollapseCO] = useState(true); //Connectivity
	const [collapseBS, setCollapseBS] = useState(true); // Battery
	const [collapseHW, setCollapseHW] = useState(true); // Hot Water
	const [collapseHO, setCollapseHO] = useState(true); // House

	const [showDistinct, setShowDistinct] = useState(true);
	const [fmData, setfmData] = useState({
		hasFmData: false,
		byMinuteReports: [],
		bySecondReports: [],
	});
	const [data, setData] = useState({
		name: '',
		timeFrame: '',
		connectivity: [],
		dailyReports: [],
		halfHourlyReports: [],
		minutelyReports: [],
		bySecondReports: [],
		updated: false,
		resolution: '',
	});

	const days = {
		[TIME_FRAMES.thirty_six_months]: 1096,
		[TIME_FRAMES.twelve_months]: 366,
		[TIME_FRAMES.three_months]: 91,
	};

	const half_hours = {
		[TIME_FRAMES.twenty_one_days]: 1440 * 60 * 21,
		[TIME_FRAMES.one_week]: 1440 * 60 * 7,
	};

	const minutes = {
		[TIME_FRAMES.twenty_four_hours]: 1440 * 60,
		[TIME_FRAMES.twelve_hours]: 720 * 60,
		[TIME_FRAMES.six_hours]: 360 * 60,
		[TIME_FRAMES.one_hour]: 60 * 60,
		[TIME_FRAMES.fifteen_minutes]: 15 * 60,
	};

	const handleFetchRecords = async (facility, resolution, since = null, before = null, count = null) => {
		try {
			const res = await FleetService.getFacilityRecords(facility, resolution, since, before, count);
			return res;
		} catch (err) {
			facilityContext.setError(err);
			return await Promise.reject(err);
		}
	};

	const handleFetchConnectivityHistory = async (facility, resolution, since = null, before = null) => {
		try {
			const res = await FleetService.getFacilityConnectivityHistory(facility, resolution, since, before);
			return res;
		} catch (err) {
			facilityContext.setError(err);
			return await Promise.reject(err);
		}
	};

	const handleTimeframeChange = (facility_name) => {
		const timeFrame = facilityContext.selectedTimeFrame;
		switch (timeFrame) {
			case TIME_FRAMES.all:
				Promise.all([
					handleFetchRecords(facility_name, resolutions.week),
					handleFetchConnectivityHistory(facility_name, resolutions.week),
				]).then((d) => {
					if (!!d.length) {
						const records = sortData(d[0], timestampDay);
						const connectivity = sortData(d[1], timestampDay);
						handleSetData(
							facility_name,
							timeFrame,
							connectivity,
							records,
							[],
							[],
							[],
							false,
							resolutions.week
						);
						facilityContext.setRecords(records, timeFrame);
					}
				});
				break;
			case TIME_FRAMES.thirty_six_months:
			case TIME_FRAMES.twelve_months:
			case TIME_FRAMES.three_months:
				Promise.all([
					handleFetchRecords(facility_name, resolutions.day),
					handleFetchConnectivityHistory(facility_name, resolutions.day),
				]).then((d) => {
					if (!!d.length) {
						const sortedRecords = sortData(d[0], timestampDay);
						const sortedConnectivity = sortData(d[1], timestampDay);
						const filteredRecords = filterRecordsByTimeframe(sortedRecords, days[timeFrame], timestampDay);
						const filteredConnectivity = filterRecordsByTimeframe(
							sortedConnectivity,
							days[timeFrame],
							timestampDay
						);
						handleSetData(
							facility_name,
							timeFrame,
							filteredConnectivity,
							filteredRecords,
							[],
							[],
							[],
							false,
							resolutions.day
						);
						facilityContext.setRecords(filteredRecords, timeFrame);
					}
				});
				break;
			case TIME_FRAMES.twenty_one_days:
			case TIME_FRAMES.one_week:
				Promise.resolve(handleFetchRecords(facility_name, resolutions.half_hour)).then((d) => {
					if (!!d.length) {
						const sortedData = sortData(d, timestampSec);
						const filteredData = filterRecordsByTimeframe(sortedData, half_hours[timeFrame], timestampSec);
						handleSetData(facility_name, timeFrame, [], [], filteredData, [], [], false);
						facilityContext.setRecords(filteredData, timeFrame);
					}
				});
				break;
			case TIME_FRAMES.twenty_four_hours:
			case TIME_FRAMES.six_hours:
			case TIME_FRAMES.one_hour:
				let since;
				Promise.resolve(handleFetchRecords(facility_name, resolutions.minute)).then((d) => {
					if (!!d.length) {
						d = sortData(d, timestampSec);
						d = filterRecordsByTimeframe(d, minutes[timeFrame], timestampSec);
						since = d[d.length - 1][timestampSec] + 1;
						handleSetData(facility_name, timeFrame, [], [], [], d, []);
						facilityContext.setRecords(d, timeFrame);
					}
				});

				if (timeFrame === TIME_FRAMES.one_hour) {
					intervalId.current = setInterval(() => {
						if (Object.values(Chart.instances).some((ci) => ci.tooltip?._active?.length)) return;

						if (!!since && !hasFmRef.current && !fmData.hasFmData) {
							Promise.resolve(handleFetchRecords(facility_name, resolutions.minute, since + 1)).then(
								(d) => {
									if (!!d.length) {
										const sortedData = sortData(d, timestampSec);
										since = d[sortedData.length - 1][timestampSec] + 1;
										handleSetData(facility_name, timeFrame, [], [], [], sortedData, [], true);
										facilityContext.updateRecords(sortedData, timeFrame);
									}
								}
							);
						}
					}, 60000);
				}
				break;
			case TIME_FRAMES.fifteen_minutes:
				let sinceSec, sinceMin;
				const bySecond = handleFetchRecords(facility_name, resolutions.second);
				const byMinute = handleFetchRecords(facility_name, resolutions.minute, null, null, 15);
				Promise.all([bySecond, byMinute]).then((d) => {
					if (!!d[0].length) {
						d[0] = sortData(d[0], timestampMsec);
						sinceSec = Math.ceil(d[0][d[0].length - 1][timestampMsec] / 1000);
					}
					if (!!d[1].length) {
						d[1] = sortData(d[1], timestampSec);
						d[1] = filterRecordsByTimeframe(d[1], minutes[timeFrame], timestampSec);
						sinceMin = d[1][d[1].length - 1][timestampSec];
					}
					handleSetData(facility_name, timeFrame, [], [], [], d[1], d[0]);
					facilityContext.setRecords(d[0], timeFrame);
				});

				intervalId.current = setInterval(() => {
					if (Object.values(Chart.instances).some((ci) => ci.tooltip?._active?.length)) return;

					if (!!sinceSec) {
						const bySecond = handleFetchRecords(facility_name, resolutions.second, sinceSec);
						const byMinute =
							!!sinceMin && sinceSec - sinceMin >= 120
								? handleFetchRecords(facility_name, resolutions.minute, sinceMin + 1)
								: [];
						Promise.all([bySecond, byMinute]).then((d) => {
							if (!!d) {
								let sortedByMinute = [];
								const sortedData = sortData(d[0], timestampMsec);
								if (!!d[0].length) {
									sinceSec = Math.ceil(sortedData[sortedData.length - 1][timestampMsec] / 1000);
								}
								if (!!d[1].length) {
									sortedByMinute = sortData(d[1], timestampSec);
									sinceMin = sortedByMinute[sortedByMinute.length - 1][timestampSec];
								}

								handleSetData(facility_name, timeFrame, [], [], [], sortedByMinute, sortedData, true);
								facilityContext.updateRecords(sortedData, timeFrame);
							}
						});
					}
				}, 6000);
				break;
			case TIME_FRAMES.select:
				const { startDate, endDate } = facilityContext;
				if (startDate && endDate) {
					const [since, before, resolution] = getSinceAndBefore(startDate, endDate, timezone);
					if (since < before) {
						if (resolution === resolutions.day) {
							Promise.all([
								handleFetchRecords(facility_name, resolution, since, before),
								handleFetchConnectivityHistory(facility_name, resolution, since, before),
							]).then((d) => {
								handleRangeData(facility_name, timeFrame, d, resolution);
							});
						} else {
							Promise.resolve(handleFetchRecords(facility_name, resolution, since, before)).then((d) => {
								handleRangeData(facility_name, timeFrame, d, resolution);
							});
						}
					}
				}
				break;
		}
	};

	const handleFmData = (since) => {
		const allowedTimeFrames = [...CONTRACT_TIMEFRAME, TIME_FRAMES.select];
		if (allowedTimeFrames.indexOf(facilityContext.selectedTimeFrame) >= 0) {
			if (facilityContext.selectedTimeFrame === TIME_FRAMES.select) {
				const timestamp = getUnixTime(zonedTimeToUtc(subDays(new Date(), 15), timezone));
				if (data.resolution !== resolutions.minute || since < timestamp) return;
			}

			const bySecond = handleFetchRecords(facilityName, resolutions.second, since);
			const byMinute = handleFetchRecords(facilityName, resolutions.minute, since, since + 900);
			Promise.all([bySecond, byMinute]).then((d) => {
				if (!!d[0].length && !!d[1].length) {
					const sortedBySecond = sortData(d[0], timestampMsec);
					const sortedByMinute = sortData(d[1], timestampSec);
					hasFmRef.current = true;
					setfmData({ hasFmData: true, byMinuteReports: sortedByMinute, bySecondReports: sortedBySecond });
				}
			});
		}
	};

	const handleSetData = (
		name,
		timeFrame,
		connectivity,
		dailyReports,
		halfHourlyReports,
		minutelyReports,
		bySecondReports,
		updated = false,
		resolution = ''
	) => {
		resetFmData();
		setData({
			name,
			timeFrame,
			connectivity,
			dailyReports,
			halfHourlyReports,
			minutelyReports,
			bySecondReports,
			updated,
			resolution,
		});
	};

	const handleRangeData = (facility_name, timeFrame, d, resolution) => {
		const { startDate, endDate } = facilityContext;
		const dates = { start: format(startDate, 'dd/MM/yy'), end: format(endDate, 'dd/MM/yy') };
		let data = [];
		let connectivity = [];

		resetFmData();
		switch (resolution) {
			case resolutions.day:
				data = sortData(d[0], timestampDay);
				connectivity = sortData(d[1], timestampDay);
				handleSetData(facility_name, timeFrame, connectivity, data, [], [], [], false, resolution);
				break;
			case resolutions.half_hour:
				data = sortData(d, timestampSec);
				handleSetData(facility_name, timeFrame, [], [], data, [], [], false, resolution);
				break;
			case resolutions.minute:
				data = sortData(d, timestampSec);
				handleSetData(facility_name, timeFrame, [], [], [], data, [], false, resolution);
				break;
			default:
				break;
		}

		facilityContext.setRecords(data, timeFrame, dates);
	};

	const resetFmData = () => {
		if (fmData.hasFmData) {
			hasFmRef.current = false;
			setfmData({ hasFmData: false, byMinuteReports: [], bySecondReports: [] });
		}
	};

	const sortData = (data, timestamp) => {
		return data.sort((a, b) => a[timestamp] - b[timestamp]);
	};

	const resizeWindow = () => {
		setInnerWidth(window.innerWidth);
	};

	useEffect(() => {
		const timezone = facilityContext.facility.timezone;
		if (!!facilityName && !!timezone && !fleetContext.tokenUpdating) {
			clearInterval(intervalId.current);
			handleTimeframeChange(facilityName);
		}
		return () => clearInterval(intervalId.current);
	}, [
		facilityContext.selectedTimeFrame,
		facilityContext.startDate,
		facilityContext.endDate,
		facilityContext.facility.druId,
		fleetContext.tokenUpdating
	]);

	useEffect(() => {
		resetFmData();
	}, [facilityContext.resetZoom]);

	useEffect(() => {
		resizeWindow();
		window.addEventListener('resize', resizeWindow);
		return () => window.removeEventListener('resize', resizeWindow);
	}, []);

	useEffect(() => {
		if (UserService.hasPreferences()) {
			const preference = UserService.getPreferences();
			const isDistinct = !!preference ? preference[config.facilityData] === distinct : true;
			setShowDistinct(isDistinct);
		}
		return () => {
			facilityContext.setRecords([], TIME_FRAMES.twenty_four_hours);
			facilityContext.setStartDate(null);
			facilityContext.setEndDate(null);
		};
	}, []);

	const showConnectivityChart = (currentTimeFrame) => {
		const dayResolution = [
			TIME_FRAMES.all,
			TIME_FRAMES.thirty_six_months,
			TIME_FRAMES.twelve_months,
			TIME_FRAMES.three_months,
		];

		if (dayResolution.includes(facilityContext.selectedTimeFrame)) {
			return true;
		}

		if (currentTimeFrame === TIME_FRAMES.select) {
			const { startDate, endDate, timezone } = facilityContext;
			const [resolution, _] = getResolutionAndAddDay(startDate, endDate, timezone);
			return resolution === resolutions.day;
		}

		return false;
	};

	const getHeights = () => {
		const showConnectivity = showConnectivityChart(facilityContext.selectedTimeFrame);
		const leftHeights = [null, null, null]; // DemandResponseChart, DruStateChart, ConnectivityChart
		const rightHeights = [null, null, null]; // BatteryChart, HotWaterChart, HouseChart
		let w = 100;
		let topSub = 0;
		let topHeight = 30;
		let bottomLeftHeight = 70;
		let bottomRightHeight = 70;

		if (innerWidth > 1440) {
			if (canAccessDrBattery) {
				w = w / 2;
				if (hasBattery && hasHotWater) {
					rightHeights[0] = `${100 / 3}%`; // BatteryChart
					rightHeights[1] = `${100 / 3}%`; // HotWaterChart
					rightHeights[2] = `${100 / 3}%`; // HouseChart
				} else if (hasBattery) {
					rightHeights[0] = '50%'; // BatteryChart
					rightHeights[2] = '50%'; // HouseChart
				} else if (hasHotWater) {
					rightHeights[1] = '50%'; // HotWaterChart
					rightHeights[2] = '50%'; // HouseChart
				}

				if (showConnectivity) {
					leftHeights[0] = '25%'; // DemandResponseChart
					leftHeights[1] = '50%'; // DruStateChart
					leftHeights[2] = '25%'; // ConnectivityChart
				} else {
					leftHeights[0] = '50%'; // DemandResponseChart
					leftHeights[1] = '50%'; // DruStateChart
				}
			} else {
				topHeight = 100;
				bottomLeftHeight = 0;
				bottomRightHeight = 0;
			}
			const leftCount = leftHeights.reduce((count, h) => (h !== null ? (count += 1) : count), 0);
			const rightCount = rightHeights.reduce((count, h) => (h !== null ? (count += 1) : count), 0);
			const lcc =
				(collapseDR && leftHeights[0] !== null ? 1 : 0) +
				(collapseDS && leftHeights[1] !== null ? 1 : 0) +
				(collapseCO && leftHeights[2] !== null ? 1 : 0);
			const rcc =
				(collapseBS && rightHeights[0] !== null ? 1 : 0) +
				(collapseHW && rightHeights[1] !== null ? 1 : 0) +
				(collapseHO && rightHeights[2] !== null ? 1 : 0);
			if (!!leftCount) {
				if (lcc < 3) {
					const px = 20 * (leftCount - lcc);
					leftHeights[0] =
						leftHeights[0] !== null && collapseDR
							? `calc(calc(100% - ${px}px) / ${lcc})`
							: leftHeights[0] !== null
							? '20px'
							: leftHeights[0];
					leftHeights[1] =
						leftHeights[1] !== null && collapseDS
							? `calc(calc(100% - ${px}px) / ${lcc})`
							: leftHeights[1] !== null
							? '20px'
							: leftHeights[1];
					leftHeights[2] =
						leftHeights[2] !== null && collapseCO
							? `calc(calc(100% - ${px}px) / ${lcc})`
							: leftHeights[2] !== null
							? '20px'
							: leftHeights[2];
				}
			}

			if (!!rightCount) {
				if (rcc < 3) {
					const px = 20 * (rightCount - rcc);
					rightHeights[0] =
						rightHeights[0] !== null && collapseBS
							? `calc(calc(100% - ${px}px) / ${rcc})`
							: rightHeights[0] !== null
							? '20px'
							: rightHeights[0];
					rightHeights[1] =
						rightHeights[1] !== null && collapseHW
							? `calc(calc(100% - ${px}px) / ${rcc})`
							: rightHeights[1] !== null
							? '20px'
							: rightHeights[1];
					rightHeights[2] =
						rightHeights[2] !== null && collapseHO
							? `calc(calc(100% - ${px}px) / ${rcc})`
							: rightHeights[2] !== null
							? '20px'
							: rightHeights[2];
				}
			}

			if (!lcc && !rcc) {
				const m = leftCount > rightCount ? leftCount : rightCount;
				topHeight = 100;
				topSub = 20 * m;
			}
		} else {
			if (canAccessDrBattery) {
				leftHeights[0] = collapseDR ? '320px' : '20px';
				leftHeights[1] = collapseDS ? '320px' : '20px';
				if (showConnectivity) {
					leftHeights[2] = collapseCO ? '320px' : '20px';
				}
				if (hasBattery) {
					rightHeights[0] = collapseBS ? '320px' : '20px';
					rightHeights[2] = collapseHO ? '320px' : '20px';
				}
				if (hasHotWater) {
					rightHeights[1] = collapseHW ? '320px' : '20px';
					rightHeights[2] = collapseHO ? '320px' : '20px';
				}
			}
		}

		let th = !collapseEF ? '20px' : `calc(${topHeight}% - ${topSub}px)`;
		let blh = !collapseEF ? 'calc(100% - 20px)' : !topSub ? `${bottomLeftHeight}%` : `${topSub}px`;
		let brh = !collapseEF ? 'calc(100% - 20px)' : !topSub ? `${bottomRightHeight}%` : `${topSub}px`;
		if (innerWidth <= 1440) {
			th = !collapseEF ? '20px' : '320px';
			blh = 'fit-content';
			brh = 'fit-content';
		}

		return [leftHeights, rightHeights, w, th, blh, brh];
	};

	const getTitleResolution = (title) => {
		let timeframe = facilityContext.selectedTimeFrame;
		if (timeframe === TIME_FRAMES.select) {
			switch (data.resolution) {
				case resolutions.week:
					timeframe = TIME_FRAMES.all;
					break;
				case resolutions.day:
					timeframe = TIME_FRAMES.thirty_six_months;
					break;
				case resolutions.half_hour:
					timeframe = TIME_FRAMES.twenty_one_days;
					break;
				case resolutions.minute:
					timeframe = TIME_FRAMES.twenty_four_hours;
					break;
				case resolutions.second:
					timeframe = TIME_FRAMES.fifteen_minutes;
					break;
			}
		} else if (fmData.hasFmData) {
			timeframe = TIME_FRAMES.fifteen_minutes;
		}

		switch (facilityContext.selectedTimeFrame) {
			case TIME_FRAMES.all:
				return title + ' by week in facility timezone';
			case TIME_FRAMES.thirty_six_months:
			case TIME_FRAMES.twelve_months:
			case TIME_FRAMES.three_months:
				return title + ' by day in facility timezone';
			case TIME_FRAMES.twenty_one_days:
			case TIME_FRAMES.one_week:
				return title + ' by half-hour';
			case TIME_FRAMES.twenty_four_hours:
			case TIME_FRAMES.six_hours:
			case TIME_FRAMES.one_hour:
				return title + ' by minute';
			case TIME_FRAMES.fifteen_minutes:
				switch (title) {
					case energyFlow:
					case demandResponse:
					case druState:
					case connectivity:
					case house:
						return title + ' by second';
					case battery:
					case hotWater:
						return title + ' by minute';
					default:
						return title;
				}
			default:
				return title;
		}
	};

	const extractSelectedTimezone = () => {
		if (facilityContext.selectedTimezone.length) {
			const list = facilityContext.selectedTimezone.split(' | ');
			return list[list.length - 1].trim();
		}

		return '';
	};

	const renderCharts = () => {
		const [leftHeights, rightHeights, w, th, blh, brh] = getHeights();
		return (
			<>
				<div
					style={{
						width: '100%',
						height: th,
					}}
				>
					<CollapsiblePanel
						title={getTitleResolution(energyFlow)}
						setCollapse={setCollapseEF}
						height={`100%`}
					>
						<EnergyChart
							fmData={fmData}
							handleFmData={(timestamp) => handleFmData(timestamp)}
							data={data}
							showDistinct={showDistinct}
							showHotWater={hasHotWater}
							showBattery={hasBattery}
							timezone={timezone}
							selectedTimezone={extractSelectedTimezone()}
                            isTypeDC={isTypeDC}
						/>
					</CollapsiblePanel>
				</div>
				{canAccessDrBattery && (
					<div
						id="left-section"
						style={{
							width: `${w}%`,
							height: blh,
						}}
					>
						{!!leftHeights[0] && (
							<CollapsiblePanel
								title={getTitleResolution(demandResponse)}
								setCollapse={setCollapseDR}
								height={leftHeights[0]}
							>
								<DemandResponseChart
									height={'100%'}
									fmData={fmData}
									handleFmData={(timestamp) => handleFmData(timestamp)}
									data={data}
									showDistinct={showDistinct}
									timezone={timezone}
									selectedTimezone={extractSelectedTimezone()}
								/>
							</CollapsiblePanel>
						)}
						{!!leftHeights[1] && (
							<CollapsiblePanel
								title={getTitleResolution(druState)}
								setCollapse={setCollapseDS}
								height={leftHeights[1]}
							>
								<DruStateChart
									height={'100%'}
									fmData={fmData}
									handleFmData={(timestamp) => handleFmData(timestamp)}
									data={data}
									timezone={timezone}
									selectedTimezone={extractSelectedTimezone()}
								/>
							</CollapsiblePanel>
						)}
						{!!leftHeights[2] && (
							<CollapsiblePanel
								title={getTitleResolution(connectivity)}
								setCollapse={setCollapseCO}
								height={leftHeights[2]}
							>
								<ConnectivityChart
									height={'100%'}
									data={data}
									showDistinct={showDistinct}
									showHotWater={hasHotWater}
									showBattery={hasBattery}
									timezone={timezone}
									selectedTimezone={extractSelectedTimezone()}
								/>
							</CollapsiblePanel>
						)}
					</div>
				)}
				<div
					id="right-section"
					style={{
						width: `${w}%`,
						height: brh,
					}}
				>
					{!!rightHeights[0] && (
						<CollapsiblePanel
							title={getTitleResolution(battery)}
							setCollapse={setCollapseBS}
							height={rightHeights[0]}
						>
							<BatteryChart
								height={'100%'}
								fmData={fmData}
								handleFmData={(timestamp) => handleFmData(timestamp)}
								data={data}
								timezone={timezone}
								selectedTimezone={extractSelectedTimezone()}
							/>
						</CollapsiblePanel>
					)}
					{!!rightHeights[1] && (
						<CollapsiblePanel
							title={getTitleResolution(hotWater)}
							setCollapse={setCollapseHW}
							height={rightHeights[1]}
						>
							<HotWaterChart
								height={'100%'}
								fmData={fmData}
								handleFmData={(timestamp) => handleFmData(timestamp)}
								data={data}
								timezone={timezone}
								selectedTimezone={extractSelectedTimezone()}
							/>
						</CollapsiblePanel>
					)}
					{!!rightHeights[2] && (
						<CollapsiblePanel
							title={getTitleResolution(house)}
							setCollapse={setCollapseHO}
							height={rightHeights[2]}
						>
							<HouseChart
								height={'100%'}
								fmData={fmData}
								handleFmData={(timestamp) => handleFmData(timestamp)}
								data={data}
								timezone={timezone}
								selectedTimezone={extractSelectedTimezone()}
							/>
						</CollapsiblePanel>
					)}
				</div>
			</>
		);
	};

	return (
		<div className="line-graph">
			<div ref={outsideRef} className="facility-graphs-wrapper">
				{!!facilityContext.facility.druId && renderCharts()}
			</div>
		</div>
	);
};

export default LineChartsFacility;
